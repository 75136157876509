import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";

import Img from "../img/contact.png";
import { Footer } from "../components/Footer";
import Whatsapp from "../img/whatsapp.png";

export const ContactUs = () => {


  const DivRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const [formData, setFormData] = useState({
    name: "",
    course:"",
    email: "",
    phone: "",
    message: "",
  });
  useEffect(() => {
    if (DivRef.current) {
      DivRef.current.scrollIntoView({
          behavior: 'smooth'
      });
  }},[])



  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post("/email.php", formData);
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };
  
  return (
    <div className="sm:mt-18" ref={DivRef}>
      <section className="relative py-10 bg-white sm:py-16 lg:py-24">
        <div className="absolute inset-0">
          <img className="object-cover w-full h-full" src={Img} alt="" />
        </div>
        <div className="absolute inset-0 bg-gray-900/20"></div>
        <div className="relative ">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="mt-10 text-3xl font-bold leading-tight text-white sm:text-4xl lg:text-5xlsm:mt-3">
            Talk to Us
            </h2>
            <p className="max-w-xl mx-auto mt-3 text-base leading-relaxed text-white">
            The Journey of a Thousand Miles begins with a single step
Seamlessly navigate your academic aspirations abroad with Lambegus.
            </p>
          </div>
        </div>
        <a
        className="Whats"
        href="https://wa.me/9188565665"
        target="_blank"
        tabindex="0"
      >
        <img className="" src={Whatsapp} alt="" />
      </a>


        <div className="relative max-w-6xl mx-auto mt-6 overflow-hidden bg-white shadow-md sm:mt-12 rounded-2xl lg:mt-16">
          <div className="grid items-stretch grid-cols-1 lg:grid-cols-5 ">
            <div className="lg:col-span-3">
              <div className="p-6 sm:p-10">
                <h3 className="text-2xl font-semibold text-black">
                  Send us a message
                </h3>
                <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 gap-4 mt-3 lg:grid-cols-2">
                  <div className="mt-3">
                    <label className="block mb-1 ">Name</label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      required
                      onChange={handleChange}
                      className="w-full p-2 border rounded-md"
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="mt-3">
                    <label className="block mb-1 ">Course</label>
                    <input
                      type="text"
                      name="course"
                      value={formData.course}
                      required
                      onChange={handleChange}
                      className="w-full p-2 border rounded-md"
                      placeholder="Enter Course Name"
                    />
                  </div>
                  <div>
                    <label className="block mb-1 ">Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="w-full p-2 border rounded-md"
                      placeholder="Enter Email"
                    />
                  </div>
                  <div>
                    <label className="block mb-1 ">Phone</label>
                    <input
                      type="text"
                      name="phone"
                      value={formData.phone}
                      required
                      onChange={handleChange}
                      className="w-full p-2 border rounded-md"
                      placeholder="Enter Phone"
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <label className="block mb-1 ">Message</label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md"
                    placeholder="Enter Message"
                  ></textarea>
                </div>

                <div className="mt-3">
                  <button className="btn btn-primary" type="submit">
                    Submit
                  </button>
                </div>
              </form>
              </div>
            </div>

            <div className="bg-gray-100 lg:col-span-2">
              <div className="h-full p-6 sm:p-10">
                <div className="flex flex-col justify-between h-full">
                  <div>
                    <h4 className="text-2xl font-semibold text-black">
                      Contact info
                    </h4>

                    <div className="mt-8 space-y-7">
                      <div className="flex items-start">
                        <svg
                          className="flex-shrink-0 text-[#E10F5A] w-7 h-7"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                          />
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                        <small className="block ml-3 text-base text-gray-900">
                          
                        Lambegus Abroad Consultancy Pvt. Ltd.,<br/> CC No.64/113A, A1,A2 & 114, 1st floor,<br/>APG Complex, Kathrikkadavu- Kaloor road,
                        <br/> Kochi- 682017

                      
                        <small className='mt-2'> <br></br>                       Working Hours : 9:00am - 5:00pm
                        </small>
                        </small>
                      </div>

                      <div className="flex items-start">
                        <svg
                          className="flex-shrink-0 text-[#E10F5A] w-7 h-7"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                          />
                        </svg>
                        <small className="block ml-3 text-base text-gray-900">
                        info@lambegus.com
                        </small>
                      </div>

                      <div className="flex items-start">
                        <svg
                          className="flex-shrink-0 text-[#E10F5A] w-7 h-7"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                          />
                        </svg>
                        <div className="ml-3">
                          <small className="block text-base text-gray-900">
                          +91 91 88 56 5665
                          </small>
                          <small className="block mt-1 text-base text-gray-900">
                          +91 91 88 56 5665
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-8 lg:mt-auto">
                    <hr className="border-gray-200" />
                    <div className="flex items-center justify-between mt-7">
                      <p className="text-lg font-semibold text-black">
                        Follow us on
                      </p>

                      <ul className="flex items-center justify-end space-x-3">
                        <li>
                          <a
                            href="#"
                            title=""
                            className="
                                                    flex
                                                    items-center
                                                    justify-center
                                                    text-gray-800
                                                    transition-all
                                                    duration-200
                                                    bg-transparent
                                                    border border-gray-300
                                                    rounded-full
                                                    w-7
                                                    h-7
                                                    focus:bg-[#E10F5A]
                                                    hover:text-white
                                                    focus:text-white
                                                     hover:bg-[#E10F5A] hover:border-[#E10F5A]
                                                    focus:border-[#E10F5A]
                                                "
                          >
                            <svg
                              className="w-4 h-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                            >
                              <path d="M19.633 7.997c.013.175.013.349.013.523 0 5.325-4.053 11.461-11.46 11.461-2.282 0-4.402-.661-6.186-1.809.324.037.636.05.973.05a8.07 8.07 0 0 0 5.001-1.721 4.036 4.036 0 0 1-3.767-2.793c.249.037.499.062.761.062.361 0 .724-.05 1.061-.137a4.027 4.027 0 0 1-3.23-3.953v-.05c.537.299 1.16.486 1.82.511a4.022 4.022 0 0 1-1.796-3.354c0-.748.199-1.434.548-2.032a11.457 11.457 0 0 0 8.306 4.215c-.062-.3-.1-.611-.1-.923a4.026 4.026 0 0 1 4.028-4.028c1.16 0 2.207.486 2.943 1.272a7.957 7.957 0 0 0 2.556-.973 4.02 4.02 0 0 1-1.771 2.22 8.073 8.073 0 0 0 2.319-.624 8.645 8.645 0 0 1-2.019 2.083z"></path>
                            </svg>
                          </a>
                        </li>

                        <li>
                          <a
                            href="#"
                            title=""
                            className="
                                                    flex
                                                    items-center
                                                    justify-center
                                                    text-gray-800
                                                    transition-all
                                                    duration-200
                                                    bg-transparent
                                                    border border-gray-300
                                                    rounded-full
                                                    w-7
                                                    h-7
                                                    focus:bg-[#E10F5A]
                                                    hover:text-white
                                                    focus:text-white
                                                     hover:bg-[#E10F5A] hover:border-[#E10F5A]
                                                    focus:border-[#E10F5A]
                                                "
                          >
                            <svg
                              className="w-4 h-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                            >
                              <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path>
                            </svg>
                          </a>
                        </li>

                        <li>
                          <a
                            href="#"
                            title=""
                            className="
                                                    flex
                                                    items-center
                                                    justify-center
                                                    text-gray-800
                                                    transition-all
                                                    duration-200
                                                    bg-transparent
                                                    border border-gray-300
                                                    rounded-full
                                                    w-7
                                                    h-7
                                                    focus:bg-[#E10F5A]
                                                    hover:text-white
                                                    focus:text-white
                                                     hover:bg-[#E10F5A] hover:border-[#E10F5A]
                                                    focus:border-[#E10F5A]
                                                "
                          >
                            <svg
                              className="w-4 h-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                            >
                              <path d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248 4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008 3.004 3.004 0 0 1 0 6.008z"></path>
                              <circle cx="16.806" cy="7.207" r="1.078"></circle>
                              <path d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42 4.6 4.6 0 0 0-2.633 2.632 6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419 4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688 2.987 2.987 0 0 1-1.712 1.711 4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311 2.985 2.985 0 0 1-1.719-1.711 5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654 0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311 2.991 2.991 0 0 1 1.712 1.712 5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011z"></path>
                            </svg>
                          </a>
                        </li>

                    
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  );
};
